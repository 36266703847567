import React, { useState } from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { CRS, LatLngBounds, Point } from 'leaflet'
import { settings, mapTileUrlTemplate } from './settings'
import './App.css'
import { MinimapControl } from './Minimap'
import './fix-leaflet-maker'
import { LocationTracker } from './LocationTracker'
import { UI } from './ui'

const position: [number, number] = [0, 0]

function CustomMap() {
	const map = useMap()

	const bounds = new LatLngBounds(
		map.unproject(new Point(0, settings.h), settings.mapMaxZoom),
		map.unproject(new Point(settings.w, 0), settings.mapMaxZoom)
	)

	const center = map.unproject(
		new Point(settings.w / 2, settings.h / 2),
		settings.mapMaxZoom
	)

	map.setView(center, settings.mapDefaultZoomLevel)
	map.setMaxBounds(bounds)

	return (
		<>
			<TileLayer
				tileSize={settings.tileSize}
				maxZoom={settings.mapMaxZoom}
				minZoom={settings.mapMinZoom}
				zoomReverse
				noWrap
				url={mapTileUrlTemplate}
			/>
			<LocationTracker />
			<MinimapControl position="topright" />
			{/* <div className="characters"></div> */}
		</>
	)
}

function MonkzMap() {
	const [show, setShow] = useState(false)

	if (!show) {
		setTimeout(() => setShow(true), 200)
		return <p>Hello World</p>
	}

	return (
		<>
			<MapContainer
				center={position}
				zoom={settings.mapDefaultZoomLevel}
				crs={CRS.Simple}
				attributionControl={false}
			>
				<CustomMap />
			</MapContainer>
			{/* <UI /> */}
		</>
	)
}

export default MonkzMap
