import { Icon } from 'leaflet'

// import 'leaflet/dist/leaflet.css'
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

delete (Icon.Default as any).prototype._getIconUrl

export {}
