import React, { useState } from 'react'
import { Marker, Popup, useMapEvents } from 'react-leaflet'
import { LatLng } from 'leaflet'

export function LocationTracker() {
	const [position, setPosition] = useState<LatLng | null>(null)
	const map = useMapEvents({
		mousemove({ latlng }) {
			document.title = `${latlng.lat} ${latlng.lng}`
		},
		mouseout() {
			document.title = 'MONKZ'
		},
		click(e) {
			setPosition(e.latlng)
			map.flyTo(e.latlng, map.getZoom())
		},
	})

	return position === null ? null : (
		<Marker position={position}>
			<Popup>
				Is this you? ({position.lat} {position.lng})
			</Popup>
		</Marker>
	)
}
