export const settings = {
	w: 36000,
	h: 16648,
	tileSize: 512,
	mapMinZoom: 1,
	mapMaxZoom: 6,
	mapDefaultZoomLevel: 4,
	mapName: 'splinterlands',
	mapInstance: 'pretoria-1',
}

export const mapTileUrlTemplate = `https://map.monkz.duplo.org/tiles/${settings.mapName}/${settings.mapInstance}/{z}/{y}x{x}.jpg`
