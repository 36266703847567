import React, { useCallback, useMemo, useState } from 'react'
import {
	MapContainer,
	TileLayer,
	useMap,
	useMapEvent,
	Rectangle,
} from 'react-leaflet'
import { useEventHandlers } from '@react-leaflet/core'
import { CRS, LeafletMouseEventHandlerFn, Map } from 'leaflet'
import { settings, mapTileUrlTemplate } from './settings'

const BOUNDS_STYLE = { weight: 1 }

function MinimapBounds({ parentMap, zoom }: { parentMap: Map; zoom: number }) {
	const minimap = useMap()

	useMapEvent(
		'click',
		useCallback<LeafletMouseEventHandlerFn>(
			({ latlng, originalEvent }) => {
				// Make sure no events are propagated to other map
				originalEvent.stopPropagation()
				parentMap.setView(latlng, parentMap.getZoom())
			},
			[parentMap]
		)
	)

	// Keep track of bounds in state to trigger renders
	const [bounds, setBounds] = useState(parentMap.getBounds())
	const onChange = useCallback(() => {
		setBounds(parentMap.getBounds())
		// Update the minimap's view to match the parent map's center and zoom
		minimap.setView(parentMap.getCenter(), zoom)
	}, [minimap, parentMap, zoom])

	// Listen to events on the parent map
	const handlers = useMemo(
		() => ({ move: onChange, zoom: onChange }),
		[onChange]
	)
	useEventHandlers(
		{
			instance: parentMap,
			context: undefined as any,
		},
		handlers
	)

	return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}
export function MinimapControl({ position }: { position: string }) {
	const parentMap = useMap()
	const zoom = 0.3
	const size = 150

	// Memoize the minimap so it's not affected by position changes
	const minimap = useMemo(
		() => (
			<MapContainer
				style={{ height: size, width: size }}
				className={'minimap'}
				center={parentMap.getCenter()}
				zoom={zoom}
				zoomSnap={0.1}
				dragging={false}
				doubleClickZoom={false}
				scrollWheelZoom={false}
				attributionControl={false}
				zoomControl={false}
				crs={CRS.Simple}
			>
				<TileLayer
					tileSize={settings.tileSize}
					maxZoom={settings.mapMaxZoom}
					minZoom={0.5}
					maxNativeZoom={settings.mapMaxZoom}
					minNativeZoom={settings.mapMinZoom}
					zoomReverse
					url={mapTileUrlTemplate}
				/>
				<MinimapBounds parentMap={parentMap} zoom={zoom} />
			</MapContainer>
		),
		[parentMap]
	)

	return (
		<div className="leaflet-top leaflet-right">
			<div className="leaflet-control leaflet-bar">
				<div className="minimap-frame">
					{minimap}
				</div>
			</div>
		</div>
	)
}
